.background-image-1 {
  background-image: url('../../assets/images/landing/Rectangle\ 5844\ \(2\).png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 550px;
  border-radius: 10px;
}
.background-image-2 {
  background-image: url('../../assets/images/landing/Rectangle\ 5845.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 550px;
  border-radius: 10px;
}

