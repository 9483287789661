.background-portal-1 {
  background-image: url('../../assets/images/ai-powered/Rectangle\ 5844.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 550px;
  border-radius: 10px;
}
.background-portal-2 {
  background-image: url('../../assets/images/ai-powered/Rectangle\ 5845.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 550px;
  border-radius: 10px;
}
