@import './akruAnalyzer.css';
@import './tenanPortal.css';
@import './comingSoon.css';
@import './rems.css';
body {
  margin: 0px;
  padding: 0px;
}


.container {
  width: 1100px;
  margin: 0px auto;
}
.container-responsive {
  width: 80%;
  margin: 0px auto;
}

